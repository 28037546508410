<template>
  <div>
    <b-button
      v-b-modal.modal-check-url
      variant="outline-info"
      @click="getCheckURLData()"
    >
      <feather-icon icon="CheckCircleIcon" /> Cek URL Scraper
    </b-button>

    <b-modal id="modal-check-url" title="Cek URL Scraper" hide-footer>
      <template #modal-title>
        <div class="d-flex align-items-center">
          <feather-icon size="20" icon="CheckCircleIcon" class="mr-05" />
          <span class="fs-18">Cek URL Scraper</span>
        </div>
      </template>
      <div>
        <b-form-group>
          <validation-observer ref="importRules">
            <validation-provider
              #default="{ errors }"
              name="File Excel"
              rules="required"
            >
              <label for="file-excel">
                File Excel <span class="text-danger">*</span>
              </label>
              <b-form-file
                color="success"
                v-model="excel_file"
                :state="Boolean(excel_file)"
                placeholder="Pilih File..."
                drop-placeholder="Drop File Disini..."
              ></b-form-file>
              <small class="text-danger" style="font-size: 10px">{{
                errors[0]
              }}</small>
            </validation-provider>
          </validation-observer>
        </b-form-group>
        <div class="d-flex justify-content-end">
          <b-button variant="info" size="sm" @click.prevent="importFile">
            Upload File
          </b-button>
        </div>
      </div>
      <div class="mt-5">
        <div class="h5">Daftar File</div>
        <loader-component v-if="is_loading" />
        <div v-else class="scroller" style="max-height: 35rem;">
          <div
            v-if="check_url_data.length == 0"
            class="alert alert-warning mt-1 py-1"
          >
            <center>
              <p class="fs-14">
                <strong>Mohon Maaf! Data Tidak Tersedia</strong>
              </p>
            </center>
          </div>
          <div v-else>
            <div
              v-for="(item, index) in check_url_data"
              class="border border-secondary mb-1 rounded-lg"
              style="padding: 10px;border-style: dashed !important;"
            >
              <div class="d-flex justify-content-between align-items-center">
                {{ cutStringText(item.file_name) }}
                <b-button
                  v-if="item.status == 'Success'"
                  size="sm"
                  variant="success"
                  @click="downloadFile(item._id)"
                >
                  <feather-icon icon="DownloadIcon" class="mr-05" />
                  Download
                </b-button>
                <b-button
                  v-else-if="item.status == 'Running'"
                  size="sm"
                  variant="warning"
                >
                  <feather-icon icon="LoaderIcon" class="mr-05" />
                  Berjalan
                </b-button>
                <b-button
                  v-else-if="item.status == 'Failed'"
                  size="sm"
                  variant="danger"
                >
                  <i class="bi bi-x-circle mr-05"></i>
                  Gagal
                </b-button>
                <b-button
                  v-else-if="item.status == 'Pending'"
                  size="sm"
                  variant="secondary"
                >
                  <feather-icon icon="AlertCircleIcon" class="mr-05" />
                  Pending
                </b-button>
              </div>
              <hr />
              <div class="d-flex align-center mt-1">
                <small class="mr-3"
                  ><strong>Dibuat:</strong>
                  {{ parseDate(item.created_at) }}</small
                >
                <small v-if="'finished_at' in item" class="ml-auto">
                  <strong>Selesai:</strong> {{ parseDate(item.finished_at) }}
                </small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { BModal, VBModal, BButton, BFormFile, BFormGroup } from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, between } from "@validations";
import useJwt from "@/auth/jwt/useJwt";
import loader from "@/views/components/loader.vue";
import helper from "@/helper/helper.js";

export default {
  name: "check-url",
  data() {
    return {
      is_import_loading: false,
      is_loading: false,
      excel_file: null,
      check_url_data: [],
      required,
      between,
    };
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    BModal,
    BButton,
    BFormFile,
    BFormGroup,
    "loader-component": loader,
  },
  methods: {
    parseDate(date) {
      return helper.dateParser(date);
    },
    importFile() {
      this.$refs.importRules.validate().then((success) => {
        if (success) {
          this.is_import_loading = true;
          let api = process.env.VUE_APP_API_URL + "scraper/check-url/import";
          let formData = new FormData();
          formData.append("file", this.excel_file);

          useJwt.axiosIns
            .post(api, formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then(() => {
              this.getCheckURLData();
            })
            .catch((err) => {
              console.log(err);
            })
            .finally(() => {
              this.is_import_loading = false;
            });
        }
      });
    },
    downloadFile(id) {
      let params = {
        id: id,
      };
      let query = Object.keys(params)
        .map((key) => key + "=" + params[key])
        .join("&");
      let api =
        process.env.VUE_APP_API_URL + "scraper/check-url/export?" + query;

      window.open(api);
    },
    getCheckURLData() {
      this.is_loading = true;
      let params = {
        page: 1,
        item: 100,
      };
      let query = Object.keys(params)
        .map((key) => key + "=" + params[key])
        .join("&");
      let api =
        process.env.VUE_APP_API_URL + "scraper/check-url/items?" + query;
      useJwt.axiosIns
        .get(api)
        .then((res) => {
          this.check_url_data = res.data.check_url_data || [];
        })
        .finally(() => {
          this.is_loading = false;
        });
    },
    cutStringText(text) {
      if (text.length > 35) {
        return text.substring(0, 35) + "...";
      }
      return text;
    },
  },
  directives: {
    "b-modal": VBModal,
  },
};
</script>
